<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->

    <AError
      v-if="tokenCheckApi.isError"
      :api="tokenCheckApi"
      :callbackReset="() => (tokenCheckApi.isError = false)"
    ></AError>
    <AError
      v-if="invitationApi.isError"
      :api="invitationApi"
      :callbackReset="() => (invitationApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Teacher Invitation</v-card-title>
      <div v-if="data.User" class="d-flex align-center justify-space-around">
        <div class="form-content text-left">
          You have been invited by {{ data.User.name }} to join
          {{ data.Institution.name }} as a teacher.
          <div v-if="auth && auth.Session">
            <div class="text-center pt-3">
              <v-btn width="75%" rounded color="primary" @click="accept()" :loading="invitationApi.isLoading" dark>
                Accept
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="reject()"
              >
                Reject
              </v-btn>
            </div>
          </div>
          <div v-else class="pt-2">
            Register or login to accept this invitation.
            <div class="text-center pt-3">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :to="{
                  name: 'PageMainRegister',
                  query: { token: this.$route.query.token },
                }"
              >
                Register
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                :to="{
                  name: 'PageMainLogin',
                  query: { token: this.$route.query.token },
                }"
              >
                Login
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    data: {},
    step: 1,
    //BOC:[api]
    tokenCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },

    invitationApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {},
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Home",
      to: { name: "PageMainHome" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Teacher Invitation",
      to: { name: "PageEducatorInstitutionTeacherInvite" },
      exact: false,
    });

    //EOC

    this.tokenCheckApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/checkInvitationToken";

    this.tokenCheckApi.callbackReset = () => {
      this.tokenCheckApi.isLoading = true;
      this.tokenCheckApi.isError = false;
    };

    this.tokenCheckApi.callbackError = (e) => {
      this.tokenCheckApi.isLoading = false;
      this.tokenCheckApi.isError = true;
      this.tokenCheckApi.error = e;
    };

    this.tokenCheckApi.callbackSuccess = (resp) => {
      this.tokenCheckApi.isLoading = false;
      this.data = resp;
    };

    this.invitationApi.method = "GET";

    this.invitationApi.callbackReset = () => {
      this.invitationApi.isLoading = true;
      this.invitationApi.isError = false;
    };

    this.invitationApi.callbackError = (e) => {
      this.invitationApi.isLoading = false;
      this.invitationApi.isError = true;
      this.invitationApi.error = e;
    };

    this.invitationApi.callbackSuccess = (resp) => {
      console.log(resp)
      this.invitationApi.isLoading = false;
      this.$store.dispatch("resetToken");
      this.$router.push({ name: "PageEducatorDashboard" });
    };
  },
  mounted() {
    this.$store.commit("updateToken", {
      token: this.$route.query.token,
    });
    this.tokenCheckApi.params = {
      token: this.$route.query.token,
    };
    this.$api.fetch(this.tokenCheckApi);
  },

  methods: {
    close() {
      this.$router.push({ name: "PageEducatorDashboard" });
    },
    reject() {
      this.$store.dispatch("resetToken");
      this.$router.push({ name: "PageEducatorDashboard" });
    },
    accept() {
      this.invitationApi.url =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/educator/institution/" +
        this.data.Institution.id +
        "/teacher/acceptInvitation";
      this.$api.fetch(this.invitationApi);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>